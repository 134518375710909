import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TimeSvg } from '../assets/svg/time.svg';
import { ReactComponent as StressSvg } from '../assets/svg/meditation.svg';
import { ReactComponent as GradeSvg } from '../assets/svg/career-growth.svg';

function Benefits() {
    const { t } = useTranslation();

    return (
        <section className="bg-dark-gray py-8">
            <div className="max-w-5xl mx-auto px-8 grid grid-cols-1 md:grid-cols-3 gap-12 text-white text-center">
                <div className="flex flex-col items-center">
                    <div className="mb-4">
                        <TimeSvg fill="#fff" className="w-12 h-12" />
                    </div>
                    <h1 className="text-xl font-bold">{t('benefits.moreTime.title')}</h1>
                    <p className="mt-2">{t('benefits.moreTime.description')}</p>
                </div>
                <div className="flex flex-col items-center">
                    <div className="mb-4">
                        <StressSvg fill="#fff" className="w-12 h-12" />
                    </div>
                    <h1 className="text-xl font-bold">{t('benefits.noStress.title')}</h1>
                    <p className="mt-2">{t('benefits.noStress.description')}</p>
                </div>
                <div className="flex flex-col items-center">
                    <div className="mb-4">
                        <GradeSvg fill="#fff" className="w-12 h-12" />
                    </div>
                    <h1 className="text-xl font-bold">{t('benefits.betterResults.title')}</h1>
                    <p className="mt-2">{t('benefits.betterResults.description')}</p>
                </div>
            </div>
        </section>
    );
}

export default Benefits;
