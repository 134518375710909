import React from 'react';
import { useTranslation } from 'react-i18next';
import myPhoto from '../assets/images/my-photo.png';

function About() {
    const { t } = useTranslation();

    return (
        <section className="md:pb-12 pt-12 bg-purple-700 text-white">
            <div className="max-w-7xl mx-auto px-8 flex flex-col md:flex-row items-center text-start">
                <div className="md:w-1/2">
                    <p className="text-lg">{t('about.introduction')}</p>
                    <h2 className="text-4xl font-bold mb-4 relative">
                        {t('about.greeting')} <span className="relative inline-block">
                            <span className="text-yellow-500 z-10 relative text-light-green">Dominik!</span>
                            <span className="absolute bottom-0 left-0 w-full h-2/3  z-0"></span>
                        </span>
                    </h2>
                    <div className="flex md:hidden md:w-1/2 my-8 justify-center">
                        <div className="bg-gray-300 rounded-lg w-full md:h-full flex items-center justify-center overflow-hidden shadow-custom-white">
                            <img src={myPhoto} alt="Dominik" loading="lazy" className="object-cover w-full h-full" />
                        </div>
                    </div>
                    <p className="mb-6 text-lg">{t('about.bio')}</p>
                    <p className="mb-6 text-lg"><strong>{t('about.timeValue')}</strong> {t('about.quizonator')}</p>
                    <p className="mb-6 text-lg">{t('about.invitation')}</p>
                </div>
                <div className="hidden md:flex md:w-1/2 mt-8 md:mt-0 justify-center">
                    <div className="bg-gray-300 rounded-lg w-full md:max-w-lg h-96 md:h-full flex items-center justify-center overflow-hidden shadow-custom-white">
                        <img src={myPhoto} alt="Dominik" className="object-cover w-full h-full" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
