import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function Footer() {
    const { t } = useTranslation();
    const location = useLocation();
    const currentLang = location.pathname.split('/')[1] || 'pl'; // Pobiera język z URL lub ustawia "pl" jako domyślny

    const scrollToCallToAction = () => {
        const callToActionElement = document.getElementById('cta-heading');
        const submitButton = document.getElementById('submitbutton');

        if (callToActionElement && submitButton) {
            const elementPosition = callToActionElement.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({
                top: elementPosition - 15,
                behavior: 'smooth'
            });

            submitButton.classList.add('animate-rainbow');

            setTimeout(() => {
                submitButton.classList.remove('animate-rainbow');
            }, 2000);
        }
    };

    return (
        <footer className="bg-dark-gray py-6">
            <div className="max-w-7xl mx-auto px-4">
                <div className="text-center mb-8">
                    <button
                        className="bg-light-green border-none text-black font-bold py-2 px-4 rounded shadow hover:bg-light-green/90"
                        onClick={scrollToCallToAction}
                    >
                        {t('footer.becomeTester')}
                    </button>
                </div>
                <div className="border-t border-gray-600 pt-6">
                    <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8 text-gray-300 text-sm">
                        <a href={`/${currentLang}/privacy-policy`} target='__blank' className="hover:text-white transition-colors">
                            {t('footer.privacyPolicy')}
                        </a>
                        <a href={`/${currentLang}/terms-of-service`} target='__blank'  className="hover:text-white transition-colors">
                            {t('footer.termsOfService')}
                        </a>
                    </div>
                    <p className="text-gray-400 text-xs text-center mt-6">
                        © {new Date().getFullYear()} Quizonator. {t('footer.rightsReserved')}
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
