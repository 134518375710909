import React from 'react';
import { useTranslation } from 'react-i18next';

function Testimonials() {
    const { t } = useTranslation();

    return (
        <section className="py-12">
            <div className="max-w-7xl mx-auto px-8 text-center">
                <h2 className="text-4xl font-bold text-dark-gray mb-12">{t('testimonials.title')}</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="relative bg-white p-6 rounded-lg border-2 border-black shadow-custom-green">
                        <div className="items-center mb-4 text-start">
                            <h3 className="text-lg font-bold text-dark-gray">{t('testimonials.bartek.name')}</h3>
                            <h2 className="text-sm text-light-gray">{t('testimonials.bartek.university')}</h2>
                        </div>
                        <p className="text-gray-600 mb-4 text-justify">{t('testimonials.bartek.feedback')}</p>
                        <div className="flex justify-start">
                            <span className="text-yellow text-3xl tracking-widest">★★★★★</span>
                        </div>
                    </div>
                    <div className="relative bg-white p-6 rounded-lg border-2 border-black shadow-custom-green">
                        <div className="items-center mb-4 text-start">
                            <h3 className="text-lg font-bold text-dark-gray">{t('testimonials.dominika.name')}</h3>
                            <h2 className="text-sm text-light-gray">{t('testimonials.dominika.university')}</h2>
                        </div>
                        <p className="text-gray-600 mb-4 text-justify">{t('testimonials.dominika.feedback')}</p>
                        <div className="flex justify-start">
                            <span className="text-yellow text-3xl tracking-widest">★★★★★</span>
                        </div>
                    </div>
                    <div className="relative bg-white p-6 rounded-lg border-2 border-black shadow-custom-green">
                        <div className="items-center mb-4 text-start">
                            <h3 className="text-lg font-bold text-dark-gray">{t('testimonials.wiktor.name')}</h3>
                            <h2 className="text-sm text-light-gray">{t('testimonials.wiktor.university')}</h2>
                        </div>
                        <p className="text-gray-600 mb-4 text-justify">{t('testimonials.wiktor.feedback')}</p>
                        <div className="flex justify-start">
                            <span className="text-yellow text-3xl tracking-widest">★★★★★</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonials;
