import React from 'react';
import { useTranslation } from 'react-i18next';

const TermsOfService = () => {
  const { t } = useTranslation();

  const sections = [
    'section1',
    'section2',
    'section3',
    'section4',
    'section5',
    'section6',
    'section7',
    'section8',
    'section9',
    'section10'
  ];

  return (
    <div className="min-h-screen p-8 bg-white text-gray-800">
      <div className="container text-left mx-auto rounded-lg p-6">
        <h1 className="text-4xl font-bold mb-6">{t('termsOfService.title')}</h1>
        <p className="mb-6 text-lg">{t('termsOfService.lastUpdated')}</p>

        {sections.map((sectionKey) => (
          <section className="mb-8" key={sectionKey}>
            <h2 className="text-2xl font-semibold mb-4">{t(`termsOfService.${sectionKey}.title`)}</h2>
            {t(`termsOfService.${sectionKey}.content`) && (
              <p className="mb-4 whitespace-pre-line">{t(`termsOfService.${sectionKey}.content`)}</p>
            )}
          </section>
        ))}
      </div>
    </div>
  );
};

export default TermsOfService;
