import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function CallToAction() {
    const { t } = useTranslation();
    const [remainingSlots, setRemainingSlots] = useState(50);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isTokenChecked, setIsTokenChecked] = useState(false);

    const location = useLocation();
    const currentLang = location.pathname.split('/')[1] || 'pl';

    useEffect(() => {
        async function validateTesterToken() {
            const params = new URLSearchParams(window.location.search);
            let validateToken = params.get("token");

            if (!validateToken) {
                validateToken = localStorage.getItem("validateToken");
            }

            if (validateToken) {
                const response = await fetch(`https://us-central1-quizonator-a4513.cloudfunctions.net/api/verify-tester-token`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ token: validateToken })
                });
                const data = await response.json();

                if (data.isValid) {
                    setIsAuthorized(true);
                    localStorage.setItem("validateToken", validateToken);
                } else {
                    localStorage.removeItem("validateToken");
                }
            }

            setIsTokenChecked(true);
        }

        validateTesterToken();
    }, []);

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const handleEmailChange = (event) => {
        const email = event.target.value;
        setIsEmailValid(validateEmail(email));
        setEmailErrorMessage(validateEmail(email) ? '' : t('callToAction.invalidEmail'));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const emailInput = document.getElementById('mce-EMAIL').value;

        if (!validateEmail(emailInput)) {
            setEmailErrorMessage(t('callToAction.invalidEmail'));
            return;
        }

        setIsPopupVisible(true);
        document.getElementById('mc-embedded-subscribe-form').submit();

        const params = new URLSearchParams(window.location.search);
        const inviteToken = params.get("invite");

        if (inviteToken) {
            await fetch(`https://us-central1-quizonator-a4513.cloudfunctions.net/markInviteAsUsed?token=${inviteToken}`);
        }
    };

    return (
        <section id="call-to-action" aria-labelledby="cta-heading" className="bg-purple">
            <div className="flex flex-col md:flex-row xl:h-screen">
                <div className="md:w-1/2 block md:hidden mt-0 md:mt-0">
                    <img src="/laptop-student.webp" alt="Młody student w okularach, uśmiechnięty, pracuje na laptopie." className="w-full h-full object-cover" width="1920" height="1080" />
                </div>
                <div className="md:w-1/2 sm:p-4 pb-6 mt-4 md:mt-12" id='cta-heading' >
                    <div className='max-w-2xl m-auto px-4'>
                        <h1 id="cta-heading" className="md:text-5xl text-4xl font-bold text-white text-start">
                            {t('callToAction.heading')}
                        </h1>
                        <p className="text-white mt-4 text-start md:text-3xl text-xl">
                            {t('callToAction.description')}
                        </p>
                        {isAuthorized ? (
                            <div className="mt-8 md:mt-20 border-2 border-white rounded-3xl p-6 shadow-lg text-start bg-opacity-90 bg-purple-700" >
                                <h3 className="text-4xl text-white font-extrabold mb-4">{t('callToAction.congratulations')}</h3>
                                <p className="text-white text-lg mb-4 leading-relaxed">
                                    {t('callToAction.registerPrompt')}
                                </p>
                                <p className="text-white text-lg mb-6">
                                    {t('callToAction.registerInstruction')}
                                </p>
                                <a  href={`/${currentLang}/register`} className="block w-full text-center">
                                    <button
                                        id="submitbutton"
                                        className="w-full py-3 font-semibold text-xl bg-light-green rounded-lg shadow-md transition-transform duration-200 ease-in-out hover:bg-light-green/90 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-light-green">
                                        {t('callToAction.registerButton')}
                                    </button>
                                </a>
                            </div>
                        ) : (
                            <div className="mt-8 md:mt-20 border-2 border-white rounded-3xl p-6 shadow-lg text-start bg-opacity-90 bg-purple-700" >
                                <h3 className="text-4xl text-white font-extrabold mb-4">{t('callToAction.contact')}</h3>
                                <p className="text-white text-lg mb-4 leading-relaxed">
                                    {t('callToAction.contactDescription')}
                                </p>
                                <p className="text-white text-lg mb-1">{t('callToAction.emailLabel')}</p>
                                <p className="font-bold text-lg mb-4 text-light-green underline">{t('callToAction.email')}</p>
                                <button
                                    id="submitbutton"
                                    className="w-full py-3 font-semibold text-xl bg-light-green rounded-lg shadow-md transition-transform duration-200 ease-in-out hover:bg-light-green/90 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-light-green"
                                    onClick={() => window.scrollTo({ top: document.getElementById('more-info').offsetTop, behavior: 'smooth' })}
                                >
                                    {t('callToAction.learnMore')}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="md:w-1/2 hidden md:block mt-0 md:mt-0">
                    <img src="/laptop-student.webp" alt="Młody student w okularach, uśmiechnięty, pracuje na laptopie." className="w-full h-full object-cover" width="1920" height="1080" />
                </div>
            </div>
        </section>
    );
}

export default CallToAction;
