import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import CallToAction from './Components/CallToAction';
import Benefits from './Components/Benefits';
import Testimonials from './Components/Testimonials';
import FAQ from './Components/FAQ';
import Footer from './Components/Footer';
import Comparison from './Components/Comparison';
import About from './Components/About';
import QuzionatorInfo from './Components/QuzionatorInfo';
import Register from './Components/Register';
import PrivacyPolicy from './Components/Policy/PrivacyPolicy';
import Rules from './Components/Policy/TermsOfService';
import MoreInfo from './Components/MoreInfo';
import ResetPassword from './Components/ResetPassword';
import './i18n';

function LanguageBasedRedirect() {
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const language = location.pathname.split('/')[1];
    if (['pl', 'en', 'uk', 'ru'].includes(language)) {
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage('pl'); // Domyślny język, jeśli brak w URL
    }
  }, [location, i18n]);

  return null;
}

function SeoHelmet() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const currentLang = i18n.language;
  const baseUrl = "https://quizonator.com";
  const canonicalUrl = `${baseUrl}/${currentLang}${location.pathname === "/" ? "" : location.pathname}`;

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
      <link rel="alternate" href={`${baseUrl}/pl${location.pathname}`} hreflang="pl" />
      <link rel="alternate" href={`${baseUrl}/en${location.pathname}`} hreflang="en" />
      <link rel="alternate" href={`${baseUrl}/uk${location.pathname}`} hreflang="uk" />
      <link rel="alternate" href={`${baseUrl}/ru${location.pathname}`} hreflang="ru" />
      <link rel="alternate" href={`${baseUrl}/`} hreflang="x-default" />
    </Helmet>
  );
}

function AppContent() {
  return (
    <Routes>
      <Route path="/:lang/register" element={<Register />} />
      <Route path="/:lang/reset-password" element={<ResetPassword />} />
      <Route path="/:lang/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/:lang/terms-of-service" element={<Rules />} />

      {/* Główna strona dla każdego języka */}
      <Route path="/:lang" element={
        <>
          <CallToAction />
          <Benefits />
          <QuzionatorInfo />
          <MoreInfo />
          <Comparison />
          <Testimonials />
          <About />
          <FAQ />
          <Footer />
        </>
      } />

      <Route path="/" element={<Navigate to="/pl" />} />

      <Route path="*" element={<Navigate to="/pl" />} />
    </Routes>
  );
}

function App() {
  return (
    <HelmetProvider>
      <Router>
        <LanguageBasedRedirect /> 
        <SeoHelmet /> 
        <AppContent />
      </Router>
    </HelmetProvider>
  );
}

export default App;
