import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    const sections = [
        'section1',
        'section2',
        'section3',
        'section4',
        'section5',
        'section6',
        'section7',
        'section8',
        'section9',
        'section10',
    ];

    return (
        <div className="min-h-screen p-8 bg-white text-gray-800">
            <div className="container text-left mx-auto rounded-lg p-6">
                <h1 className="text-4xl font-bold mb-6">{t('privacyPolicy.title')}</h1>
                <p className="mb-6 text-lg">{t('privacyPolicy.lastUpdated')}</p>

                {sections.map((sectionKey) => {
                    const section = t(`privacyPolicy.${sectionKey}`, { returnObjects: true });
                    return (
                        <section className="mb-8" key={sectionKey}>
                            <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
                            {section.description && (
                                <p className="mb-4 whitespace-pre-line">{section.description}</p>
                            )}
                            {Object.keys(section)
                                .filter((key) => key !== 'title' && key !== 'description')
                                .map((subKey) => {
                                    const subSection = section[subKey];
                                    if (subSection.title && subSection.description) {
                                        return (
                                            <div key={subKey}>
                                                <h3 className="text-xl font-semibold mb-2">{subSection.title}</h3>
                                                <p className="mb-4 whitespace-pre-line">{subSection.description}</p>
                                            </div>
                                        );
                                    } else if (subKey === 'rights' && typeof subSection === 'object') {
                                        return (
                                            <div key={subKey}>
                                                {Object.values(subSection).map((right, index) => (
                                                    <p key={index} className="mb-2">
                                                        {right}
                                                    </p>
                                                ))}
                                            </div>
                                        );
                                    } else if (subKey === 'contact') {
                                        return (
                                            <p key={subKey} className="mb-4">
                                                {subSection}
                                            </p>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                        </section>
                    );
                })}
            </div>
        </div>
    );
};

export default PrivacyPolicy;
