import React from 'react';
import { useTranslation } from 'react-i18next';
import quizonatorLogo from '../assets/images/quizonator-logo.png';
import chatgptLogo from '../assets/images/chatgpt-logo.png';

function Comparison() {
  const { t } = useTranslation();

  return (
    <section className='bg-dark-gray'>
      <div className="text-white md:p-8 pb-6 pt-12 p-2 rounded-lg max-w-5xl mx-auto">
        <h1 className="text-center text-4xl font-bold mb-16">{t('comparison.title')}</h1>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-center mb-8">
          <div className='hidden md:block md:col-span-2'></div>
          <div className="flex flex-col items-center justify-center">
            <img
              src={quizonatorLogo}
              className="w-16 h-16 md:w-24 md:h-24 mb-2  flex items-center justify-center text-dark-gray font-bold text-xl md:text-4xl"
            >
            </img>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img
              src={chatgptLogo}
              className="w-16 h-16 md:w-24 md:h-24 mb-2 flex items-center justify-center text-dark-gray font-bold text-xl md:text-4xl"
            >
            </img>
          </div>
        </div>
        {[
          {
            label: 'comparison.autoFindQuestions',
            quizonator: 'comparison.yes',
            chatgpt: 'comparison.no'
          },
          {
            label: 'comparison.responseTime',
            quizonator: 'comparison.quizonatorResponse',
            chatgpt: 'comparison.chatGptResponse'
          },
          {
            label: 'comparison.undetectableExams',
            quizonator: 'comparison.yes',
            chatgpt: 'comparison.no'
          },
          {
            label: 'comparison.latestModelUsage',
            quizonator: 'comparison.latestModel',
            chatgpt: 'comparison.latestModel'
          }
        ].map((item, index) => (
          <div
            key={index}
            className={`grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 ${index > 0 ? 'mt-4 md:mt-6' : ''
              }`}
          >
            <div className="col-span-2 py-2 px-2 text-center md:text-left text-xs sm:text-sm md:text-xl">
              {t(item.label)}
            </div>
            <div className="py-2 flex items-center justify-center text-sm md:text-xl text-neon-green font-bold">
              {t(item.quizonator)}
            </div>
            <div className="py-2 flex items-center justify-center text-sm md:text-xl text-white">
              {t(item.chatgpt)}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Comparison;
