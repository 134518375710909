import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import logo from '../assets/images/quizonator-logo.png';

function Register() {
    const { t } = useTranslation();
    const location = useLocation();
    const currentLang = location.pathname.split('/')[1] || 'pl';

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [isValidToken, setIsValidToken] = useState(false);
    const [showThankYouModal, setShowThankYouModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isAgreedToPrivacy, setIsAgreedToPrivacy] = useState(false);
    const [isAgreedToTerms, setIsAgreedToTerms] = useState(false);


    const validatePassword = (password) => {
        const minLength = 8;
        const hasLetter = /[a-zA-Z]/.test(password);
        const hasNumber = /\d/.test(password);

        if (password.length < minLength) {
            return t('register.errors.shortPassword');
        }
        if (!hasLetter) {
            return t('register.errors.noLetter');
        }
        if (!hasNumber) {
            return t('register.errors.noNumber');
        }
        return '';
    };

    useEffect(() => {
        const validateToken = localStorage.getItem("validateToken");
        if (validateToken) {
            axios.post('https://us-central1-quizonator-a4513.cloudfunctions.net/api/verify-tester-token', { token: validateToken })
                .then(() => {
                    setIsValidToken(true);
                })
                .catch(() => {
                    setIsValidToken(false);
                    setErrorMessage(t('register.errors.invalidToken'));
                    navigate('/');
                });
        } else {
            navigate('/');
        }
    }, [navigate, t]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage('');
        setIsLoading(true);

        if (!isAgreedToPrivacy || !isAgreedToTerms) {
            setIsLoading(false);
            setErrorMessage(t('register.errors.agreeToPolicies'));
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setIsLoading(false);
            setErrorMessage(t('register.errors.passwordsNotMatching'));
            return;
        }

        const passwordError = validatePassword(formData.password);
        if (passwordError) {
            setIsLoading(false);
            setErrorMessage(passwordError);
            return;
        }

        if (formData.username.length > 20) {
            setIsLoading(false);
            setErrorMessage(t('register.errors.usernameTooLong'));
            return;
        }

        const validateToken = localStorage.getItem("validateToken");
        axios.post('https://us-central1-quizonator-a4513.cloudfunctions.net/api/register-with-token', { ...formData, token: validateToken })
            .then(() => {
                setIsLoading(false);
                setShowThankYouModal(true);
                localStorage.removeItem("validateToken");
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    setIsLoading(false);
                    setErrorMessage(error.response.data.message || t('register.errors.generic'));
                } else {
                    setIsLoading(false);
                    setErrorMessage(t('register.errors.generic'));
                }
            });
    };

    if (!isValidToken) {
        return null;
    }

    return (
        <div className='bg-gradient-to-r from-green-400 to-blue-500 w-screen h-screen flex items-center justify-center'>
            <div className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-lg">
                <div className='flex items-center justify-center mb-12 md:px-16 px-4'>
                    <img src={logo} alt={t('resetPassword.logoAlt')} className="w-12 h-12 mr-3" />
                    <h1 className="text-4xl font-bold text-green-500">Quizonator</h1>
                </div>

                <h2 className="text-2xl font-bold text-green-500 mb-6 text-center">{t('register.title')}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                            placeholder={t('register.username')}
                            className="w-full p-2 border-2 border-green-400 rounded-3xl pl-5 focus:outline-none hover:shadow-custom-green-sm transition duration-300 transform hover:scale-105 focus:scale-105 focus:shadow-custom-green-sm"
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            placeholder={t('register.email')}
                            className="w-full p-2 border-2 border-green-400 rounded-3xl pl-5 focus:outline-none hover:shadow-custom-green-sm transition duration-300 transform hover:scale-105 focus:scale-105 focus:shadow-custom-green-sm"
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            placeholder={t('register.password')}
                            className="w-full p-2 border-2 border-green-400 rounded-3xl pl-5 focus:outline-none hover:shadow-custom-green-sm transition duration-300 transform hover:scale-105 focus:scale-105 focus:shadow-custom-green-sm"
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                            placeholder={t('register.confirmPassword')}
                            className="w-full p-2 border-2 border-green-400 rounded-3xl pl-5 focus:outline-none hover:shadow-custom-green-sm transition duration-300 transform hover:scale-105 focus:scale-105 focus:shadow-custom-green-sm"
                        />
                    </div>
                    <div className="mb-4 flex items-center">
                        <input
                            type="checkbox"
                            id="privacyPolicy"
                            checked={isAgreedToPrivacy}
                            onChange={() => setIsAgreedToPrivacy(!isAgreedToPrivacy)}
                            className="mr-2 w-4 h-4 accent-green-400 border-2 border-green-400 rounded cursor-pointer"
                        />
                        <label htmlFor="privacyPolicy" className="select-none text-gray-700 cursor-pointer">
                            {t('register.privacyPolicy')} <a href={`/${currentLang}/privacy-policy`} target='_blank' className="underline text-green-600 hover:text-green-700">{t('footer.privacyPolicy')}</a>
                        </label>
                    </div>
                    <div className="mb-4 flex items-center">
                        <input
                            type="checkbox"
                            id="termsOfService"
                            checked={isAgreedToTerms}
                            onChange={() => setIsAgreedToTerms(!isAgreedToTerms)}
                            className="mr-2 w-4 h-4 accent-green-400 border-2 border-green-400 rounded cursor-pointer"
                        />
                        <label htmlFor="termsOfService" className="select-none text-gray-700 cursor-pointer">
                            {t('register.termsOfService')} <a href={`/${currentLang}/terms-of-service`} target='_blank' className="underline text-green-600 hover:text-green-700">{t('footer.termsOfService')}</a>
                        </label>
                    </div>

                    <button
                        type="submit"
                        className="w-full py-2 bg-green-400 text-white font-bold rounded-full hover:bg-green-500 transition duration-200"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <div className="flex justify-center items-center">
                                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                                <span className="ml-2">{t('register.loading')}</span>
                            </div>
                        ) : t('register.submit')}
                    </button>
                </form>

                {errorMessage && (
                    <div className="mt-4 p-4 bg-red-100/80 text-red-600 border border-red-300 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
                        {errorMessage}
                    </div>
                )}
            </div>

            {showThankYouModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 animate-fade-in">
                    <div className="bg-white shadow-custom-green p-4 md:p-8 rounded-md text-center animate-pop-up max-w-xs sm:max-w-sm md:max-w-lg w-full md:max-w-2xl lg:max-w-4xl">
                        <h2 className="text-2xl md:text-3xl font-bold text-green-500 mb-2 md:mb-4">{t('register.thankYou.title')}</h2>
                        <p className="mb-4 md:mb-6 text-base md:text-lg">{t('register.thankYou.message')}</p>
                        <a
                            href="https://chromewebstore.google.com/detail/quizonator/dhphdklknbffcgahfpiipdmafoncnndi"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block w-full py-2 bg-green-400 text-white font-bold rounded-full hover:bg-green-500 transition duration-200 mb-4"
                        >
                            {t('register.thankYou.button')}
                        </a>
                        <button
                            onClick={() => {
                                setShowThankYouModal(false);
                                window.location.href = `/${currentLang}`;
                            }}
                            className="mt-4 py-2 px-4 md:px-6 bg-light-green font-bold rounded-md shadow-sm hover:bg-light-green/90 focus:outline-none focus:ring-2 focus:ring-light-green transition duration-200 ease-in-out"
                        >
                            {t('register.thankYou.close')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Register;
