import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StarSvg } from '../assets/svg/medical-star.svg';

function QuzionatorInfo() {
    const { t } = useTranslation();

    return (
        <section className="py-12 bg-white text-dark-gray" id='more-info'>
            <div className="max-w-7xl mx-auto px-8 text-center md:p-0">
                <h2 className="text-4xl font-bold mb-8">{t('quzionatorInfo.title')}</h2>
                <div className="text-left space-y-8 sm:p-8">
                    <p className="text-lg text-justify">
                        {t('quzionatorInfo.description')}
                    </p>
                    <div className="flex items-start p-2">
                        <div className="w-6 h-6 mr-4 flex-shrink-0 flex items-center justify-center mt-2">
                            <StarSvg className='w-full h-full' fill="#6b21a8" />
                        </div>
                        <p className="text-lg flex-1">
                            {t('quzionatorInfo.studentCreated')}
                        </p>
                    </div>
                    <div className="flex items-start p-2">
                        <div className="w-6 h-6 mr-4 flex-shrink-0 flex items-center justify-center mt-2">
                            <StarSvg className='w-full h-full' fill="#6b21a8" />
                        </div>
                        <p className="text-lg flex-1">
                            {t('quzionatorInfo.testedByStudents')}
                        </p>
                    </div>
                    <div className="flex items-start p-2">
                        <div className="w-6 h-6 mr-4 flex-shrink-0 flex items-center justify-center mt-2">
                            <StarSvg className='w-full h-full' fill="#6b21a8" />
                        </div>
                        <p className="text-lg flex-1">
                            {t('quzionatorInfo.undetectable')}
                        </p>
                    </div>
                    <div className="flex items-start p-2">
                        <div className="w-6 h-6 mr-4 flex-shrink-0 flex items-center justify-center mt-2">
                            <StarSvg className='w-full h-full' fill="#6b21a8" />
                        </div>
                        <p className="text-lg flex-1">
                            {t('quzionatorInfo.feature')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default QuzionatorInfo;
