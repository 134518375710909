import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function Faq() {
    const { t } = useTranslation();
    const [activeIndices, setActiveIndices] = useState([]);

    const toggleFAQ = (index) => {
        if (activeIndices.includes(index)) {
            setActiveIndices(activeIndices.filter((i) => i !== index));
        } else {
            setActiveIndices([...activeIndices, index]);
        }
    };

    const faqs = [
        {
            question: t('faq.questions.question1'),
            answer: t('faq.answers.answer1'),
        },
        {
            question: t('faq.questions.question2'),
            answer: t('faq.answers.answer2'),
        },
        {
            question: t('faq.questions.question3'),
            answer: t('faq.answers.answer3'),
        },
        {
            question: t('faq.questions.question4'),
            answer: t('faq.answers.answer4'),
        },
    ];

    return (
        <section className="py-12 bg-white">
            <div className="max-w-4xl mx-auto px-4">
                <h2 className="text-4xl font-semibold text-center text-black mb-8">{t('faq.title')}</h2>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="rounded-lg p-4 text-dark-gray">
                            <button
                                className="w-full text-left flex justify-between items-center text-lg font-semibold border-b pb-2"
                                onClick={() => toggleFAQ(index)}
                            >
                                {faq.question}
                                <span className={`transform transition-transform ${activeIndices.includes(index) ? 'rotate-180' : 'rotate-0'}`}>
                                    &#9662;
                                </span>
                            </button>
                            {activeIndices.includes(index) && (
                                <div className="mt-4 text-gray-700 text-justify">
                                    <p>{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Faq;
