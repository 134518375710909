import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/quizonator-logo.png';

const ResetPassword = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleResetPassword = async () => {
        setIsLoading(true);
        try {
            const response = await fetch('https://us-central1-quizonator-a4513.cloudfunctions.net/api/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setMessage(t('resetPassword.successMessage'));
            } else {
                const errorData = await response.json();
                setMessage(`${t('resetPassword.errorMessage')}: ${errorData.message}`);
            }
        } catch (error) {
            setMessage(t('resetPassword.fetchError'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='bg-gradient-to-r from-green-400 to-blue-500 w-screen h-screen flex items-center justify-center'>
            <div className="w-full max-w-md mx-auto bg-white p-8 rounded-lg shadow-lg text-center">

                <div className='flex items-center justify-center mb-12'>
                    <img src={logo} alt={t('resetPassword.logoAlt')} className="w-12 h-12 mr-3" />
                    <h1 className="text-4xl font-bold text-green-500">Quizonator</h1>
                </div>

                <h2 className="text-2xl font-bold text-green-500 mb-6">{t('resetPassword.title')}</h2>
                <form onSubmit={(e) => { e.preventDefault(); handleResetPassword(); }}>
                    <div className="mb-4">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder={t('resetPassword.emailPlaceholder')}
                            className="w-full p-2 border-2 border-green-400 rounded-3xl pl-5 focus:outline-none hover:shadow-custom-green-sm transition duration-300 transform hover:scale-105 focus:scale-105 focus:shadow-custom-green-sm"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 bg-green-400 text-white font-bold rounded-full hover:bg-green-500 transition duration-200"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <div className="flex justify-center items-center">
                                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                                <span className="ml-2">{t('resetPassword.sending')}</span>
                            </div>
                        ) : t('resetPassword.submitButton')}
                    </button>

                    {message && (
                        <div className="mt-4 p-4 bg-blue-100/80 text-blue-600 border border-blue-300 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg text-sm sm:text-base md:text-lg lg:text-xl">
                            {message}
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
