import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importy plików tłumaczeń
import pl from './locales/pl.json';
import en from './locales/en.json';
import ru from './locales/ru.json';
import uk from './locales/uk.json';

// Inicjalizacja `i18next`
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            pl: { translation: pl },
            en: { translation: en },
            ru: { translation: ru },
            uk: { translation: uk },


        },
        fallbackLng: 'pl',
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['path', 'localStorage', 'navigator'], // Kolejność: ścieżka URL, localStorage, język przeglądarki
            caches: ['localStorage'] // Zapisywanie wyboru języka w localStorage
        }
    });

// Wykrywanie języka z URL i ustawienie języka początkowego
const languageFromPath = window.location.pathname.split('/')[1];
if (['pl', 'en', 'ru', 'uk'].includes(languageFromPath)) {
    i18n.changeLanguage(languageFromPath);
} else {
    // Jeśli brak języka w ścieżce, użyj ostatnio zapisanego języka z localStorage lub domyślnego.
    const savedLanguage = localStorage.getItem('i18nextLng') || 'pl';
    i18n.changeLanguage(savedLanguage);
}

export default i18n;
